<template>
  <div class="page">
    <div class="pageHead">
      <div class="pageTitle" :style="getStyleConfig('welCome')">
        <div class="titleCon">
          <span></span>
          <p>{{ getText('welCome') }}</p>
          <div
            class="lang"
            @click="pageLangChange"
            v-if="getConfig.langType"
          >
            {{ getText('lang') }}
          </div>
          <span v-else></span>
        </div>
        <img
          :src="getConfigText('welComeType')"
          alt=""
          v-if="getStyleConfig('welComeType')"
        />
      </div>
      <div class="pageBanner" @click="linkTo(getText('topBannerLink'))">
        <img class="bannerLogo" :src="webJson.topBanner.icon" alt="" />
      </div>
    </div>
    <div class="pageCon">
      <div
        class="countDown"
        v-if="
          getTimeSate(webJson.countdown.time) &&
          parseInt(webJson.countdown.offset) === 1 &&
          webJson.countdown.switch
        "
        :style="getStyleConfig('countDown')"
      >
        <countDown
          :time="webJson.countdown.time"
          :type="webJson.countdown.type"
          :fontBgColor="webJson.countdown.fontBgColor"
          :fontColor="webJson.countdown.fontColor"
        ></countDown>
        <img
          :src="getConfigText('countType')"
          alt=""
          v-if="getStyleConfig('countType')"
        />
      </div>
      <div class="pageMenu" :style="getStyleConfig('menu')">
        <div class="menuGroup" :style="getStyleConfig('menuGroup')">
          <div
            class="navItem"
            v-for="(item, index) in pageLang === 1
              ? webJson.navList
              : webJson.enNavList"
            :key="index"
            :style="getStyleConfig('navItem')"
          >
            <span
              class="navTitle"
              @click="linkTo(item.link)"
              @mouseenter="navHover($event.target, 'navItem', 1)"
              @mouseleave="navLeave($event.target, 'navItem', 2)"
              >{{ item.name }}</span
            >
            <div class="itemList">
              <span
                @click="linkTo(child.link)"
                v-for="(child, index) in item.children"
                :key="index"
                :style="getStyleConfig('navItemChild')"
                @mouseenter="navHover($event.target, 'navItem2', 1)"
                @mouseleave="navLeave($event.target, 'navItem2', 2)"
                >{{ child.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="crumbs" v-if="showCrumbs()">
        <p>您的位置 ：</p>
        <Icon type="ios-home" @click="linkTo('home')" size="24" />
        <p>> {{ $route.meta.name }}</p>
      </div>
      <router-view></router-view>
    </div>
    <div class="pageFoot" :style="getStyleConfig('footer')">
      <img v-if="getConfig.footerConfig.icon"  class="pageFootBgImg" :src="getConfig.footerConfig.icon" alt="" />
      <div class="footCon">
        <div class="footInfo">
          <p
            v-for="(item, index) in webJson.footerItems"
            :key="index"
            @click="linkTo(item.link)"
          >
            {{ item.text }}
          </p>
        </div>
        <div class="footItem">
          <div
            class="footLogo"
            v-for="(item, index) in webJson.footerIcons"
            :key="index"
            @click="linkTo(item.link)"
          >
            <img :src="item.url" alt="" />
          </div>
        </div>
      </div>
    </div>
    <p class="ipcItem" @click="toIpc()">
      <span @click="toIpc(2)"> 活动嘉提供技术支持</span>
      <span @click="toIpc(1)">{{ getConfigText('ipc') }}</span>
    </p>
  </div>
</template>
<script>
import countDown from '../../../components/countDown'
// import config from "../../../webConfig";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      value1: 0,
      webJson: {
        name: '',
        ico: '',
        pageColor: '',
        langType: false,
        welCome: {
          config: {},
          enConfig: {},
        },
        topBanner: {},
        enTopBanner: {},
        navList: [],
        navConfig: {},
        enNavList: [],
        bannerConfig: {},
        countdown: {
          switch: false,
          time: '',
          offset: '',
        },
        bannerList: [],
        enBannerList: [],
        adList1: [],
        enAdList1: [],
        adList2: [],
        enAdList2: [],
        atlList: [],
        album: [],
        videoType: [],
        videos: [],
        coverAtlas: '',
        coverVideos: '',
        sponsorList: [],
        footer: {
          icon: [],
          items: [],
        },
        footerItems: [],
        footerIcons: [],
        footerConfig: {},
        coverArticle: [],
        enCoverArticle: [],
        articleTypeList: [],
        articleList: [],
      },
      countDown: {},
      alts: 0,
      pageLang: 1,
      navAct: -1,
    }
  },
  components: {
    countDown: countDown,
  },
  computed: {
    ...mapGetters(['getConfig', 'getSpinType']),
  },
  watch: {
    getConfig(val) {
      this.webJson = val
    },
  },
  methods: {
    pageLangChange() {
      this.pageLang = this.pageLang === 1 ? 2 : 1
      this.$router.push({
        name: 'pcHome',
        query: { lang: this.pageLang },
      })
    },
    getTime(time, type) {
      if (!time) {
        return ''
      }
      let d = new Date(time)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let date = d.getDate()
      // let hour = d.getHours();
      // let minute = d.getMinutes();
      // let second = d.getSeconds();
      return type ? year : month + '/' + date
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
    linkTo(link) {
      if (!link) {
        return false
      }
      if (link === 'home') {
        this.$router.push({
          name: 'pcHome',
          query: {
            lang: this.pageLang,
          },
        })
        return false
      }
      if (link.length === 8) {
        for (let x of this.webJson.articleList) {
          if (link === x.link) {
            this.$router.push({ name: 'article', query: { id: link } })
            return false
          }
        }
        return false
      }
      if (link.length === 6) {
        this.$router.push({ name: 'list', query: { id: link } })
        return false
      }
      // if (link.indexOf("alts") !== -1) {
      //   this.$router.push({ name: "alts" });
      //   return false;
      // }
      if (link === 'list') {
        this.$router.push({ name: 'list', query: { id: link } })
        return false
      }
      window.open(link)
    },
    getTimeSate(time) {
      if (time) {
        let leftTime = new Date(time) - new Date()
        return leftTime > 0
      }
      return false
    },
    getText(type) {
      if (type === 'welCome') {
        let welComeConfig =
          (this.pageLang === 1
            ? this.getConfig.welCome.config
            : this.getConfig.welCome.enConfig) || this.getConfig.welCome
        return welComeConfig.text
      }
      if (type === 'lang') {
        return this.pageLang === 1 ? 'English' : '中文'
      }
      if (type === 'topBannerLink') {
        return this.pageLang === 1
          ? this.webJson.topBanner.link
          : this.webJson.topBanner.enLink
      }
    },
    // 设置元素样式设置
    getStyleConfig(type) {
      if (type === 'welCome') {
        let welComeConfig =
          (this.pageLang === 1
            ? this.getConfig.welCome.config
            : this.getConfig.welCome.enConfig) || {}
        return {
          color: welComeConfig.color || this.getConfig.pageColor,
          fontSize: welComeConfig.size,
          backgroundColor: welComeConfig.bgColor,
        }
      }
      if (type === 'navItemChild') {
        let navConfig =
          (this.pageLang === 1
            ? this.getConfig.navConfig.cn
            : this.getConfig.navConfig.en) || {}
        return {
          backgroundColor: navConfig.mBackground || this.getConfig.pageColor,
          color: navConfig.mFont || this.getConfig.pageColor,
        }
      }
      if (type === 'welComeType') {
        let welComeConfig =
          (this.pageLang === 1
            ? this.getConfig.welCome.config
            : this.getConfig.welCome.enConfig) || {}
        return welComeConfig.type === 2
      }
      if (type === 'countType') {
        return this.getConfig.countdown.bgType === 2
      }
      if (type === 'menu') {
        let navConfig =
          (this.pageLang === 1
            ? this.getConfig.navConfig.cn
            : this.getConfig.navConfig.en) || {}
        return {
          width: navConfig.type === 2 ? '100%' : '1200px',
          color: navConfig.font,
          backgroundColor: navConfig.background,
        }
      }
      if (type === 'menuGroup') {
        let navConfig =
          (this.pageLang === 1
            ? this.getConfig.navConfig.cn
            : this.getConfig.navConfig.en) || {}
        return {
          width: navConfig.type === 2 ? '1200px' : '100%',
          color: navConfig.font,
        }
      }
      if (type === 'navItem') {
        let navConfig =
          (this.pageLang === 1
            ? this.getConfig.navConfig.cn
            : this.getConfig.navConfig.en) || {}
        return {
          backgroundColor: navConfig.background || this.getConfig.pageColor,
          color: navConfig.font || this.getConfig.pageColor,
        }
      }
      if (type === 'lang') {
        return {
          borderColor: this.getConfig.pageColor,
          color: this.getConfig.pageColor,
        }
      }
      if (type === 'footer') {
        return {
          // backgroundImage: `url(${this.getConfig.footerConfig.icon})`,
          backgroundColor: this.getConfig.footerConfig.bgColor || '#1A2E42',
        }
      }
      if (type === 'countDown') {
        return {
          width: this.getConfig.countdown.type === 2 ? '100%' : '1200px',
          backgroundColor:
            this.getConfig.countdown.bgColor || this.getConfig.pageColor,
        }
      }
    },
    // 设置元素hover样式
    navHover(ev, type) {
      let navConfig =
        (this.pageLang === 1
          ? this.getConfig.navConfig.cn
          : this.getConfig.navConfig.en) || {}
      ev.style.color =
        (type === 'navItem' ? navConfig.fontAct : navConfig.mFontAct) ||
        this.getConfig.pageColor
      ev.style.backgroundColor =
        (type === 'navItem'
          ? navConfig.backgroundAct
          : navConfig.mBackgroundAct) || this.getConfig.pageColor
    },
    navLeave(ev, type) {
      let navConfig =
        (this.pageLang === 1
          ? this.getConfig.navConfig.cn
          : this.getConfig.navConfig.en) || {}
      ev.style.color =
        (type === 'navItem' ? navConfig.font : navConfig.mFont) ||
        this.getConfig.pageColor
      ev.style.backgroundColor =
        (type === 'navItem' ? navConfig.background : navConfig.mBackground) ||
        this.getConfig.pageColor
    },
    toIpc(type) {
      if (type === 2) {
        window.open('http://www.huodongzhongguo.com')
        return false
      }
      if (this.getConfig.footerConfig.url) {
        window.location.href = this.getConfig.footerConfig.url
        // window.open(this.getConfig.footerConfig.url,"_self")
      }
    },
    // 设置元素内容
    getConfigText(type) {
      let str = ''
      if (type === 'welCome') {
        let welComeConfig =
          (this.pageLang === 1
            ? this.getConfig.welCome.config
            : this.getConfig.welCome.enConfig) || {}
        str = welComeConfig.text || '暂无欢迎语，请在右边进行【欢迎语配置】'
      }
      if (type === 'lang') {
        str = this.pageLang === 1 ? 'English' : '中文'
      }
      if (type === 'welComeType') {
        let welComeConfig =
          (this.pageLang === 1
            ? this.getConfig.welCome.config
            : this.getConfig.welCome.enConfig) || {}
        str = welComeConfig.icon
      }
      if (type === 'countType') {
        str = this.getConfig.countdown.icon
      }
      if (type === 'footImg') {
        str = this.getConfig.footerConfig.icon
      }
      if (type === 'ipc') {
        if (this.getConfig.footerConfig.ipc) {
          str = ' | ICP备案号' + this.getConfig.footerConfig.ipc
        }
      }
      return str
    },
    showCrumbs() {
      let linkArray = ['list', 'article', 'alts', 'videos']
      return linkArray.indexOf(this.$route.name) > -1
    },
  },
}
</script>

<style lang="less">
@import '../../../assets/less/home';
@import '../../../assets/less/article';
</style>
